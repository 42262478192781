<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('erp.lang_dishOfTheDayControl')"
        :icon="icon"
        :subheading="$t('erp.lang_dishOfTheDayControl')"
    ></page-title>
    <div class="app-main__inner">
      <DailyGoodsComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import CreateGarnishGroupComponent from "@/components/erp/baseData/garnishgroup/CreateGarnishGroupComponent";
import DailyGoodsComponent from "@/components/erp/baseData/dailygoods/DailyGoodsComponent";

export default {
  name: "DailyGoods",
  components: {DailyGoodsComponent, PageTitle},
  data(){
    return{
      icon:'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
