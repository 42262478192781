<template>
  <v-container fluid>
    <MiniCalendar v-model="date"/>
    <Datatable
        :api-endpoint="ENDPOINTS.DATATABLES.ERP.DAILYGOODS"
        :datatable-headers="datatableHeaders" no-excel-export
        ref="goodOfTehDay"
        :searchPayload="this.searchPayload"
        :data="params"
        :datatable-attach-search="datatableAttachSearch"
        enable-multi-sort
        :custom-right-menu-buttons="this.customRightMenuButtons"
        @import="openImportDialog"
        @export="exportPdf"
        @print="printList"
        @exportExcel="exportExcel"
    >
      <template v-slot:item.history="{item , value}">
        <div v-if="isIssetArr(value)">
          <v-chip @click="openEditHistoryDialog(item.id , i , h)" color="primary" class="ma-2"
                  v-for="(h , i) in value" :disabled="!isToday"
                  :key="i">
            {{ h }}
            <v-icon v-if="canEditQuantityHistory" small right>
              mdi-pencil-outline
            </v-icon>
          </v-chip>
        </div>
        <div v-else></div>
      </template>


      <template v-slot:item.total_meals="{value}">
        <strong>{{ value }}</strong>
      </template>

      <template v-slot:item.amount="{item}">
        <v-text-field
            dense
            v-model="item.amount" :loading="loading"
            hide-details
            type="number"
            :min="0"
            @change="updateDailyAmount(item)"
            outlined :disabled="!isToday"
        />
      </template>
    </Datatable>


    <!--start: edit history dialog-->
    <v-dialog v-model="historyDialog" max-width="600">
      <v-card>
        <v-card-text class="pt-4">
          <v-text-field @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @keypress.enter="saveQuantityHistory" type="number" :min="0" dense outlined
                        v-model="quantityHistoryForm.value"/>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn @click="closeHistoryDialog" text color="error">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>

          <v-spacer/>
          <v-btn :disabled="savingHistory" :loading="savingHistory" @click="saveQuantityHistory" text color="primary">
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: edit history dialog-->


    <!--start: import daily goods-->
    <v-dialog v-model="importDialog" max-width="600">
      <v-card>
        <v-card-title class="card-header">
          {{ this.$t('generic.lang_import_daily_goods') }}
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form lazy-validation ref="importForm">
            <v-file-input
                v-model="datFile"
                :rules="[v => !!v]"
                accept=".dat"
                :label="$t('generic.lang_attachFile')"
                hide-details
                dense outlined
            ></v-file-input>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0 card-footer">
          <v-btn @click="closeImportDialog" text color="error">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>

          <v-spacer/>
          <v-btn :disabled="importing" :loading="importing" @click="importFile" text color="primary">
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: import daily goods-->


    <v-overlay :value="this.downloading">
      <v-progress-circular size="120" color="primary" indeterminate/>
    </v-overlay>


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config';
import Datatable from "@/components/datatable/Datatable";
import Template from "@/components/settings/customerdisplay/Template";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import FileSaver from 'file-saver';
import {mapState} from "vuex";
import MiniCalendar from "../../../common/MiniCalendar";
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";

export default {
  name: "DailyGoodsComponent",
  components: {MiniCalendar, Template, Datatable},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      quantityHistoryForm: {
        value: 0,
        editItemID: 0,
        position: 0
      },
      searchPayload: {},
      datatableAttachSearch: [
        {text: this.$t('generic.lang_waregroup'), value: 'itemGroup', searchType: 'multiselect', searchCol: 4},
        {text: 'Lieferant', value: 'supplier_id', searchType: 'singleselect', searchCol: 4},
        //{text: this.$t('Producer'), value: 'producer_id', searchType: 'singleselect', searchCol: 4},
        {text: this.$t('erp.lang_item_type'), value: 'item_type', searchType: 'singleselect', searchCol: 4}
      ],
      importing: false,
      importDialog: false,
      downloading: false,
      datFile: null,
      historyDialog: false,
      savingHistory: false,
      datatableHeaders: [
        {
          text: 'id',
          value: 'id',
          searchable: true, searchCol: 6
          //hide: true,
        },
        {
          text: 'ean',
          value: 'ean',
          hide: true,
        },
        {
          text: this.$t('generic.lang_waregroup'),
          value: 'waregroup',
        },
        {
          text: this.$t('erp.lang_posItemName'),
          value: 'name',
          searchable: true, searchCol: 6
        },
        {
          text: this.$t('erp.lang_mealAmount'),
          value: 'total_meals',
        },
        {
          text: this.$t('pms.lang_history'),
          value: 'history',
          sortable:false,
        },
        {
          text: this.$t('erp.lang_mealAmount'),
          value: 'amount',
          sortable:false,
        },
      ],
      loading: false,
      date:null,
      params: {
        date:this.date,
      },//datatable additional get params
    }
  },
  watch:{
    date(v){
      this.params.date=v;
      this.$refs.goodOfTehDay.getDataFromApi();
    }
  },
  computed: {
    ...mapState([
      'settings',
      'api',
      'pos',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    canEditQuantityHistory() {
      return this.$store.getters['permissions/checkPermission']('allowChangeDishOfTheDayHistory');
    },
    customRightMenuButtons() {
      return [
        {
          title: this.$t('generic.lang_import'),
          event: "import",
          layout: {
            icon: "mdi-database-import-outline",
            iconColor: "primary"
          }
        },
        {
          title: this.$t('generic.lang_ExportAsPDF'),
          event: "export",
          layout: {
            icon: "mdi-file-pdf-box",
            iconColor: "warning"
          }
        },
        {
          title: this.$t('generic.lang_export')+' Excel',
          event: "exportExcel",
          layout: {
            icon: "mdi-file-excel-box",
            iconColor: "success"
          }
        },
        {
          title: this.$t('erp.lang_print'),
          event: "print",
          layout: {
            icon: "mdi-receipt",
            iconColor: "primary",
          }
        }
      ]
    },
    isToday(){
      return this.date===this.$moment((new Date()).getTime()).format("YYYY-MM-DD");
    }
  },
  methods: {
    getSuppliers() {
      return this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS")
          this.suppliers = res.data.suppliers;
      });
    },
    getProducers() {
      return this.axios.post(ENDPOINTS.ERP.PRODUCERS.GETALL).then((res) => {
        if (res.data.success)
          this.producers = res.data.producers;
      });
    },
    async getSearchPayload() {
      let data = {};

      //ITEMGROUPS
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      data["itemGroup"] = itemgroups.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      //SUPPLIERS
      data["supplier_id"] = this.suppliers.map((supplier) => {
        return {
          id: supplier.id,
          name: supplier.supplier_name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      //PRODUCERS
      /*  data["producer_id"] = this.producers.map((producer) => {
          return {
            id: producer.id,
            name: producer.producer_name
          }
        }).sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });*/
      data["item_type"] = [
        {
          id: 1,
          name: this.$t('generic.lang_item')
        },
        {
          id: 2,
          name: this.$t('erp.lang_extra')
        }
      ];

      /*[
        {
          id: 1,
          name: this.$t('generic.lang_item')
        },
        {
          id: 2,
          name: this.$t('erp.lang_extra')
        }
      ]*/

      this.searchPayload = data;
    },
    openImportDialog() {
      this.importDialog = true;
    },
    closeImportDialog() {
      if (this.$refs.importForm)
        this.$refs.importForm.reset();
      this.importDialog = false;
    },
    exportPdf() {
      this.downloading = true;
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.EXPORT, {
            date:this.date,
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {

        //DOWNLOAD REST OF DAILY GOODS AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "daily-goods.pdf");
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.downloading = false;
      })
    },
    exportExcel() {
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.EXPORTEXCEL,{
        date:this.date,
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), this.$t('erp.lang_dishOfTheDayControl')+".xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {
      })
    },
    importFile() {
      if (!this.$refs.importForm.validate()) return;

      let form = new FormData();
      form.append("csvFile", this.datFile);
      form.append("date", this.date);
      this.importing = true;
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.IMPORT,
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.closeImportDialog()
          this.$refs.goodOfTehDay.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' : ' + (res.data.message || res.data.status),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.importing = false
      })
    },
    openEditHistoryDialog(itemId, position, value) {
      if (!this.canEditQuantityHistory||!this.isToday) return;
      this.quantityHistoryForm.position = position;
      this.quantityHistoryForm.editItemID = itemId;
      this.quantityHistoryForm.value = value;
      this.historyDialog = true;
    },
    closeHistoryDialog() {
      this.quantityHistoryForm.position = 0;
      this.quantityHistoryForm.editItemID = 0;
      this.quantityHistoryForm.value = 0;
      this.historyDialog = false;
    },
    saveQuantityHistory() {

      this.savingHistory = true;
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.QUANTITY_HISTORY, this.quantityHistoryForm).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.closeHistoryDialog()
          this.$refs.goodOfTehDay.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' : ' + (res.data.message || res.data.status),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ': ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.savingHistory = false
      })
    },
    updateDailyAmount(item) {
      if(!this.isToday)
        return;
      if (item.amount === null || !(Number(item.amount) >= 0))
        return;

      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.AMOUNT, {
        editItemID: item.id,
        dailyAmount: Number(item.amount),
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_updatedSuccessfully'),
            color: "success"
          });
          this.$refs.goodOfTehDay.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' : ' + (res.data.message || res.data.status),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ': ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false
      })
    },
    printList() {
      if (this.$refs.goodOfTehDay.$data.dataToShow && this.$refs.goodOfTehDay.$data.dataToShow.length < 1) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ':' + this.$t('generic.lang_noDataToDisplay'),
          color: "error"
        });
        return;
      }
      if (!this.invoicePrinter) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_printReceiptError'),
          color: "error"
        });
        return;
      }
      let printObj = [];


      printObj.push({
        feed: 2,
      });
      printObj.push({
        reverse: 'on'
      });
      printObj.push({
        fontSize: 3
      });
      printObj.push({
        align: "center"
      });
      printObj.push({
        text: "Restliste"
      });
      printObj.push({
        align: "left"
      });

      printObj.push({
        reverse: 'off'
      });
      printObj.push({
        fontSize: 2
      });
      printObj.push({
        text: "------------------------"
      });

      this.$refs.goodOfTehDay.$data.dataToShow.forEach(function (item) {

        if (Number(item[6]) && Number(item[6]) > 0) {
          printObj.push({
            text: '#' + item[1] + ' ' + item[3]
          });
          printObj.push({
            text: '  rest:' + item[6],
          });
        }
      });
      printObj.push({
        text: "------------------------"
      });
      printObj.push({
        feed: 5
      });
      printObj.push({
        feed: 5
      });
      printObj.push({
        feed: 5
      });
      printObj.push({
        cut: true
      });

      printDataFromPrinter(this.invoicePrinter,printObj).then(()=>{

      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
      })

    },

  },
  async mounted() {
    await this.getSuppliers();
    //await this.getProducers();
    await this.getSearchPayload();
    if(this.params)
      this.$refs.goodOfTehDay.getDataFromApi();
  }
}
</script>

<style scoped>

</style>
