<template>
  <v-card elevation="1"  class="my-1" :outlined="false"  >
    <v-card-text class="py-0">
      <v-row no-gutters>
        <v-col cols="12">
          <div
              class="pa-0 px-0 ma-0"
          >
            <v-btn
                class="mr-0"
                color="grey darken-2"
                outlined
                @click="setToday"
            >
              {{ $t('generic.lang_today') }}
            </v-btn>
            <v-btn
                color="grey darken-2"
                fab
                icon
                text
                @click="$refs.calendar.prev()"
            >
              <v-icon large>
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
              {{ new Date(requested_date).getDate() }}
            </v-btn>


            <v-btn
                color="grey darken-2"
                fab
                icon
                small
                @click="$refs.calendar.next()"
            >
              <v-icon large>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <strong v-if="$refs.calendar" class="font-weight-bolder">
              {{ $refs.calendar.title }}
            </strong>
            <v-spacer></v-spacer>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row class="fill-height pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <div style="height: 0 !important; visibility: hidden !important;">
                <v-calendar
                    :locale="lang"
                    ref="calendar"
                    v-model="requested_date"
                    color="primary"
                    type="day"
                ></v-calendar>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- date picker dialog -->
    <v-dialog v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/*
* A component for paginating days using arrows, with a date picker dialog to select a specific date
* */
import {mapGetters} from "vuex";

export default {
  name: "MiniCalendar",
  props:["value"],
  data(){
    return{
      picker:false,
      requested_date:this.value,
    }
  },
  watch: {
    requested_date: {
      handler(newValue, oldValue) {
        this.requested_date = newValue;
        this.handleInput();
      },
    }
  },
  computed:{
    ...mapGetters({
      lang:"app/currentLanguage"
    })
  },
  methods:{
    handleInput () {
      this.$emit('input', this.requested_date)
      this.$emit('change', this.requested_date)
    },
    setToday() {
      let dt = new Date();
      this.requested_date = this.$moment(dt.getTime()).format("YYYY-MM-DD")
    },
  },
  mounted() {
    if(!this.value){
      this.setToday();
    }
  }
}
</script>

<style scoped>

</style>